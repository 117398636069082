import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '.';
import { PhotoAPIPayload, PhotosState } from '../../typedefs';

const initialState: PhotosState = [];

const slice = createSlice({
  name: 'photos',
  initialState,
  reducers: {
    setPhotos(state, action: PayloadAction<PhotoAPIPayload[]>): PhotosState {
      return action.payload;
    },
  },
});

export const getPhotos = (store: RootState): PhotoAPIPayload[] => {
  return [...store.photos];
};

export const photosActions = slice.actions;

export default slice.reducer;
