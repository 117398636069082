import { GrowthBook } from '@growthbook/growthbook-react';
import { first } from 'lodash-es';
import { ApiCallArguments, AuthenticationContext } from '../typedefs';
import CookieUtil from './cookieUtil';

let _growthbook: GrowthBook<Record<string, any>> | undefined = undefined;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function clearIdInGrowthBook() {
  if (_growthbook) {
    const { id, ...rest } = _growthbook.getAttributes();
    _growthbook.setAttributes(rest);
  }
}

function setIdInGrowthBook(username: string) {
  if (_growthbook) {
    const { id, ...rest } = _growthbook.getAttributes();
    if (id === username) return;

    _growthbook.setAttributes({
      id: username,
      ...rest,
    });
  }
}

const AuthenticationUtil = {
  setGrowthBook: (growthbook: GrowthBook<Record<string, any>>) => {
    _growthbook = growthbook;
  },
  isUnauthorizedResponseFromServer(serverCallError: any): boolean {
    // See buildErrorObject() in baseFetch.ts for the structure of this.
    if ('generalErrors' in serverCallError && serverCallError.generalErrors) {
      const { generalErrors } = serverCallError;
      const firstError = first(generalErrors);
      return firstError === 'Unauthorized';
    }
    return false;
  },
  logoutIfServerReturnsUnauthorized: async <T>(
    serverFunction: ((args?: ApiCallArguments) => Promise<T>) | ((args?: ApiCallArguments) => Promise<void>),
    authenticationContext: AuthenticationContext,
  ): Promise<T | undefined | void> => {
    try {
      const username = CookieUtil.getUsername();
      if (username) {
        setIdInGrowthBook(username);
      }

      return await serverFunction({ authenticationContext });
    } catch (e: any) {
      if (AuthenticationUtil.isUnauthorizedResponseFromServer(e)) {
        authenticationContext.logout();
        return undefined;
      }
      throw e;
    }
  },
};

export default AuthenticationUtil;
