import { concat, join } from 'lodash-es';

type PartnerSlugAndRemainingBrowserLocationPathResult = {
  partnerSlug?: string;
  path?: string;
};

const PathUtil = {
  getPartnerSlug: (pathname: string | undefined): string | undefined => {
    if (!pathname) return undefined;

    const paths = pathname.split('/');
    const partnerSlug = paths[1];
    return partnerSlug;
  },

  getPartnerSlugAndRemainderAsPath: (
    pathname: string | undefined,
  ): PartnerSlugAndRemainingBrowserLocationPathResult => {
    if (!pathname) return {};

    const parts = pathname.split('/');
    const partnerSlug = parts[1];
    const paths = parts.slice(2);

    // make sure there is an initial '/' in the resulting string
    const path = join(concat([''], paths), '/');
    return { partnerSlug, path };
  },
};

export default PathUtil;
