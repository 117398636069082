import { getData, postData } from '../redux/fetchService/baseFetch';
import { AuthenticationContext, EventRequestAPIPayload } from '../typedefs';

class HistoryAPI {
  sendEvent = async (params: EventRequestAPIPayload): Promise<void> => {
    return postData('history', params);
  };
  getAll = (authContext: AuthenticationContext): Promise<any[]> => {
    return getData('history', authContext);
  };
}

export default HistoryAPI;
