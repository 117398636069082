import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assign } from 'lodash-es';
import type { RootState } from '.';
import type { FieldErrors, FormErrors } from '../../typedefs';
import { NO_ERRORS } from '../../utils/constants';
import { formUpdateAction } from './sharedActions';

const initialState: FormErrors = NO_ERRORS;

const slice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setLocalErrors(state, action: PayloadAction<FormErrors>) {
      const { serverFieldErrors, ...rest } = action.payload;
      return {
        ...state,
        ...rest,
      };
    },
    setServerErrors(state, action: PayloadAction<unknown>) {
      const { payload } = action;
      if (typeof payload !== 'object' || payload === null) {
        return state;
      }
      const safePayload = payload as Record<string, unknown>;
      if (
        !Array.isArray(safePayload.generalErrors) ||
        safePayload.fieldErrors === null ||
        typeof safePayload.fieldErrors !== 'object'
      ) {
        return state;
      }
      const { generalErrors, fieldErrors } = safePayload;
      return {
        ...state,
        generalErrors: generalErrors as string[],
        serverFieldErrors: fieldErrors as FieldErrors,
      };
    },
    clearAllErrors() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(formUpdateAction, (state, action) => {
      const { fieldErrors, serverFieldErrors } = state;
      const newErrors = { ...fieldErrors };
      delete newErrors[action.payload.field];
      const newServerErrors = { ...serverFieldErrors };
      delete newServerErrors[action.payload.field];

      return {
        ...state,
        fieldErrors: newErrors,
        serverFieldErrors: newServerErrors,
      };
    });
  },
});

export const errorActions = slice.actions;
export const getServerFieldErrors = (state: RootState) => {
  const { serverFieldErrors } = state.errors;
  return serverFieldErrors;
};
export const getGeneralErrors = (state: RootState) => state.errors.generalErrors;

export const getFormErrors = (state: RootState): FormErrors => state.errors;

export const getFieldErrors = createSelector([getFormErrors], (formErrors): FieldErrors => {
  const { fieldErrors, serverFieldErrors } = formErrors;
  const combined = assign({}, fieldErrors, serverFieldErrors);
  return combined;
});

export default slice.reducer;
