import { Experiment, GrowthBook, Result, useGrowthBook } from '@growthbook/growthbook-react';
import { mapKeys, snakeCase, some } from 'lodash-es';
import CookieUtil from './cookieUtil';
import { EXPERIMENT_INFO, Experiments } from './experiments';
import WindowUtil from './windowUtil';

const OVERRIDES_QUERY_STRING_NAME = 'o';

const GROWTH_BOOK_DEV_MODE = process.env.NEXT_PUBLIC_GROWTH_BOOK_DEV_MODE === 'true';

export type StandardFeatureSetting = boolean;

// 1 control, 1 variant: value is boolean indicating variant is ON
export type FeatureSettings = {
  key: string;
  // TODO Can initial be undefined?
  initial: StandardFeatureSetting;
  default: StandardFeatureSetting;
};

// Unhandled test types:
//  - value configured in test platform
//  - A/B/C+: 1 control, 2+ variants; value is number indicating what is ON (control: 0, variants: 1+)

function getOverrideValuesFromQuerystring(): string[] {
  return WindowUtil.getAllFromQueryString(OVERRIDES_QUERY_STRING_NAME);
}

function getOverrideValues(): string[] {
  const overrideValues = getOverrideValuesFromQuerystring();
  if (overrideValues.length > 0) {
    CookieUtil.setExperimentOverrides(overrideValues);
    return overrideValues;
  } else {
    return CookieUtil.getExperimentOverrides();
  }
}

function findOverrideMatchForExperiment(
  experiment: FeatureSettings,
  overrideValues: string[],
): StandardFeatureSetting | undefined {
  const onValues = [experiment.key.toLowerCase(), `${experiment.key.toLowerCase()}on`];
  const offValues = [`${experiment.key.toLowerCase()}off`];

  if (
    some(overrideValues, (overrideVal) => {
      const lowerVal = overrideVal.toLowerCase();
      return some(onValues, (onVal) => onVal === lowerVal);
    })
  ) {
    return true;
  }

  if (
    some(overrideValues, (overrideVal) => {
      const lowerVal = overrideVal.toLowerCase();
      return some(offValues, (offVal) => offVal === lowerVal);
    })
  ) {
    return false;
  }

  return undefined;
}

function determineOverridesFromQueryString(experiment: FeatureSettings): StandardFeatureSetting | undefined {
  if (!experiment) return undefined;
  const overrideValues = getOverrideValues();
  const overrides = findOverrideMatchForExperiment(experiment, overrideValues);
  return overrides;
}

function evaluateExperiment(growthbook: GrowthBook | undefined, experiment: FeatureSettings): StandardFeatureSetting {
  if (!experiment) return false;

  const override = determineOverridesFromQueryString(experiment);
  if (override !== undefined) return override;

  if (!growthbook) return experiment.initial;

  const feature = growthbook.evalFeature(experiment.key);
  if (!feature) return experiment.default;

  return growthbook.isOn(experiment.key);
}

export interface WithExperimentProps {
  isFeatureOn: (key: keyof Experiments) => StandardFeatureSetting;
}

export const withExperiment = <P extends WithExperimentProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithExperimentProps>> => {
  return (props: any): JSX.Element => {
    const growthbook = useGrowthBook();

    const isFeatureOn = (key: keyof Experiments): StandardFeatureSetting => {
      if (!key) return false;
      const experiment = EXPERIMENT_INFO[key];
      return evaluateExperiment(growthbook, experiment);
    };

    return <Component {...(props as P)} isFeatureOn={isFeatureOn} />;
  };
};
withExperiment.displayName = 'WithExperiment';

export function useExperiment(experiment: FeatureSettings): StandardFeatureSetting {
  const growthbook = useGrowthBook();
  return evaluateExperiment(growthbook, experiment);
}

function extractExperimentInfo<T>(experiment: Experiment<T>, result: Result<T>) {
  const { key, name: experimentName } = experiment;
  const { variationId, featureId, name: variationName } = result;
  const bucketId = `${key}/${variationId}`;
  return { key, experimentId: key, experimentName, featureId, variationId, variationName, bucketId };
}

function transformExperimentInfoForGA(info: any) {
  return mapKeys(info, (value, key) => `gb_${snakeCase(key)}`);
}

export function createGrowthBook(onAssignToExperiment: (experimentInfo: any) => void) {
  if (!process.env.NEXT_PUBLIC_GROWTH_BOOK_CLIENT_KEY) return undefined;

  const growthBookClientKey = process.env.NEXT_PUBLIC_GROWTH_BOOK_CLIENT_KEY;
  console.log(`GrowthBook initialized with key: ***${growthBookClientKey.slice(-4)}`);

  if (GROWTH_BOOK_DEV_MODE) {
    console.log(`Initializing GrowthBook dev mode ON`);
  }

  const growthbook = new GrowthBook({
    apiHost: process.env.NEXT_PUBLIC_GROWTH_BOOK_API_HOST || 'https://cdn.growthbook.io',
    clientKey: growthBookClientKey,
    enableDevMode: GROWTH_BOOK_DEV_MODE,
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
      const info = extractExperimentInfo(experiment, result);

      if (process.env.NODE_ENV === 'development') {
        console.log(`Assigning to experiment = `, info);
      }

      if (info && onAssignToExperiment) {
        const gaData = transformExperimentInfoForGA(info);
        onAssignToExperiment(gaData);
      }
    },
  });

  return growthbook;
}

export function initializeGrowthBook(growthbook: GrowthBook<Record<string, any>>, attributes: any) {
  if (process.env.NODE_ENV === 'development') {
    console.log(`GrowthBook attributes = `, attributes);
  }

  growthbook.setAttributes(attributes);
  growthbook.init({ streaming: true });
}
