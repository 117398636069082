import { postData } from '../redux/fetchService/baseFetch';
import { FormFields, type NeighborhoodAPIPayload } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

const setNumberIfPresent = (value: string) => (value !== '' && value !== undefined ? Number(value) : undefined);

export class NeighborhoodAPI {
  fetchNeighborhoodInfo = async (params: { formData: FormFields }): Promise<NeighborhoodAPIPayload | undefined> => {
    const { formData } = params;
    const { zipCode } = formData;
    if (!zipCode) return undefined;

    return postData('neighborhood', {
      zipCode: setNumberIfPresent(zipCode),
    });
  };
}

export const fetchNeighborhoodInfoApiThunk = createApiThunk(
  'neighborhood/get',
  new NeighborhoodAPI().fetchNeighborhoodInfo,
);
