import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '.';
import type { MapsState } from '../../typedefs';

const initialState: MapsState = { loading: true, success: false };

const slice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setMapsState(state, action: PayloadAction<Partial<MapsState>>) {
      return { ...state, ...action.payload };
    },
  },
});

export const getMapsLoading = (store: RootState): boolean => store.maps.loading;
export const getMapsSuccess = (store: RootState): boolean => store.maps.success;

export const mapsActions = slice.actions;

export default slice.reducer;
