import { createAsyncThunk } from '@reduxjs/toolkit';
import ErrorUtil from '../../utils/errorUtil';

export const createApiThunk = <Returned, Args>(actionName: string, apiCall: (args: Args) => Promise<Returned>) => {
  return createAsyncThunk(actionName, async (args: Args, { rejectWithValue }) => {
    try {
      const result = await apiCall(args);
      return result;
    } catch (e) {
      ErrorUtil.logError(e);
      return rejectWithValue(e);
    }
  });
};
