import { combineReducers } from 'redux';
import errors from './errors';
import financing from './financing';
import formData from './formData';
import maps from './maps';
import modal from './modal';
import neighborhood from './neighborhood';
import photos from './photos';
import projects from './projects';
import rebates from './rebates';
import ui from './ui';

const rootReducer = combineReducers({
  errors,
  maps,
  modal,
  formData,
  projects,
  rebates,
  financing,
  photos,
  ui,
  neighborhood,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
