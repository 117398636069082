import { postData } from '../redux/fetchService/baseFetch';
import { PropertyDataAPIPayload, PropertyDataForm } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

class PropertyDataAPI {
  fetchPropertyData = async ({
    city,
    state,
    zipCode,
    streetAddress,
  }: PropertyDataForm): Promise<PropertyDataAPIPayload> => {
    return postData(`propertyData`, {
      city,
      state,
      streetAddress,
      zipCode: parseInt(zipCode, 10),
    });
  };
}

export const fetchPropertyApiThunk = createApiThunk('propertyDataApi/get', new PropertyDataAPI().fetchPropertyData);
