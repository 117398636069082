import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash-es';
import type { RootState } from '.';
import { fetchNeighborhoodInfoApiThunk } from '../../api/NeighborhoodAPI';
import type { NeighborhoodState, UtilityProvider } from '../../typedefs';
import { UtilityProviderServiceType } from '../../utils/enums';

const initialState: NeighborhoodState = {
  utilityProviders: [],
};

const slice = createSlice({
  name: 'neighborhood',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchNeighborhoodInfoApiThunk.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const isElectricProvider = (provider: UtilityProvider) => provider.serviceType === UtilityProviderServiceType.ELECTRIC;

export const getUtilityProviders = (store: RootState) => store.neighborhood.utilityProviders;

export const getElectricUtilityProviders = (store: RootState) => {
  const result = filter(store.neighborhood.utilityProviders, isElectricProvider);
  return result;
};

export const hasMoreThanOneElectricUtilityProvider = (store: RootState) => {
  const providers = getElectricUtilityProviders(store);
  const result = providers.length > 1;
  return result;
};

export const getHudAmi = (store: RootState) => store.neighborhood.hudAmi;

export default slice.reducer;
