import { ErrorInfo } from 'react';
import { FormErrors } from '../typedefs';
import { NO_ERRORS } from './constants';

const logError = (error: unknown) => {
  if (error instanceof Error) {
    if (typeof window !== 'undefined' && window.newrelic) {
      window.newrelic.noticeError(error);
    } else {
      console.log(error);
    }
  }
};

const createFormError = (message: string): FormErrors => ({
  ...NO_ERRORS,
  generalErrors: [message],
});

const getFormErrors = (error: unknown): FormErrors => {
  if (error instanceof Error) {
    return createFormError(error.message);
  }
  if (typeof error === 'string') {
    return createFormError(error);
  }
  if (typeof error === 'object') {
    const errorObj = error as Record<string, any>;
    if (
      (Object.hasOwn(errorObj, 'generalErrors') &&
        Array.isArray(errorObj.generalErrors) &&
        errorObj.generalErrors.length > 0) ||
      Object.hasOwn(errorObj, 'fieldErrors')
    ) {
      return {
        ...NO_ERRORS,
        ...errorObj,
      };
    }
  }
  return createFormError('Unknown Error');
};

const ErrorUtil = {
  logReactError(error: Error, errorInfo: ErrorInfo) {
    logError(error);
    if (!window.newrelic) {
      console.log(errorInfo);
    }
  },
  logError,
  getFormErrors,
};

export default ErrorUtil;
