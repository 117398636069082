import { FeatureSettings, useExperiment } from './experimentUtil';

export const ENABLE_INCOME_QUALIFICATION_CRITERIA_NAME = 'enable_income_qualification_criteria';

export type Experiments = {
  // [name of test]: FeatureSettings
  enable_income_qualification_criteria: FeatureSettings;
};

export const EXPERIMENT_INFO: { [key in keyof Experiments]: FeatureSettings } = {
  [ENABLE_INCOME_QUALIFICATION_CRITERIA_NAME]: {
    key: ENABLE_INCOME_QUALIFICATION_CRITERIA_NAME,
    initial: false,
    default: false,
  },
};

export function useEnableIncomeQualificationCriteriaIsOn(): boolean {
  const val = useExperiment(EXPERIMENT_INFO[ENABLE_INCOME_QUALIFICATION_CRITERIA_NAME]);
  return val as boolean;
}
useEnableIncomeQualificationCriteriaIsOn.displayName = ENABLE_INCOME_QUALIFICATION_CRITERIA_NAME;
