/* eslint-disable import/prefer-default-export */
import { createAction } from '@reduxjs/toolkit';
import { FormFields } from '../../typedefs';

export type FormUpdate = {
  field: keyof FormFields;
  value: string;
};

export type SelectorUpdate = {
  field: keyof FormFields;
  value: any;
};

export type NestedFieldUpdate = {
  field: string;
  value: any;
};

export const formUpdateAction = createAction<FormUpdate>('FORM_UPDATE');
export const selectorUpdateAction = createAction<SelectorUpdate>('SELECTOR_UPDATE');
export const nestedFieldUpdateAction = createAction<NestedFieldUpdate>('NESTED_FIELD_UPDATE');
