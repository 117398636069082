import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { AppProps } from 'next/app';
import React, { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import '../public/styles/index.scss';
import { wrapper } from '../redux/store';
import { PageProps } from '../typedefs';
import AuthenticationUtil from '../utils/authenticationUtil';
import ErrorUtil from '../utils/errorUtil';
import { createGrowthBook, initializeGrowthBook } from '../utils/experimentUtil';
import TrackingUtil from '../utils/trackingUtil';
import WindowUtil from '../utils/windowUtil';

const growthbook = createGrowthBook(TrackingUtil.assignedToExperiment);

const MyApp: FC<AppProps<PageProps>> = ({ Component, ...rest }) => {
  useEffect(() => {
    require('bootstrap/js/dist/base-component');
    require('bootstrap/js/dist/button');
    require('bootstrap/js/dist/collapse');
    require('bootstrap/js/dist/dropdown');
  }, []);

  const { pageProps } = rest;
  const { partnerSlug, secondaryTrackerId } = pageProps;

  useEffect(() => {
    console.log(`Initializing tracking with secondary tracking ID: `, secondaryTrackerId);
    const sessionId = TrackingUtil.initalize(secondaryTrackerId);

    // GTM is configured to NOT send the page_view event on page load,
    // to allow the secondary tracker to be configured above, so we send it here.
    const page = WindowUtil.getLocationPathAndQuery();
    const title = WindowUtil.getDocumentTitle();
    if (page && title) {
      TrackingUtil.pageView(page, title);
    }

    const attributes = {
      sessionId,
      partnerSlug,
    };

    if (!growthbook) return;
    initializeGrowthBook(growthbook, attributes);
    AuthenticationUtil.setGrowthBook(growthbook);
  }, [partnerSlug, secondaryTrackerId]);

  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <React.StrictMode>
      <ErrorBoundary fallback={<h1>Something went wrong.</h1>} onError={ErrorUtil.logReactError}>
        <Provider store={store}>
          {growthbook ? (
            <GrowthBookProvider growthbook={growthbook}>
              <Component {...props.pageProps} />
            </GrowthBookProvider>
          ) : (
            <Component {...props.pageProps} />
          )}
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default MyApp;
