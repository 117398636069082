function getSearchParams() {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    return params;
  }
}
const WindowUtil = {
  getFirstFromQueryString: (name: string) => {
    const params = getSearchParams();
    return params && (params.get(name) || undefined);
  },

  getAllFromQueryString: (name: string) => {
    const params = getSearchParams();
    return params ? params.getAll(name) : [];
  },

  getCustomerUuid: () => {
    return WindowUtil.getFirstFromQueryString('uuid');
  },

  getNewUrl: (newUrl: string | undefined) => {
    if (typeof window !== 'undefined' && newUrl) {
      const url = new URL(newUrl, window.location.origin);
      url.search = window.location.search;
      url.hash = window.location.hash;
      return url;
    }
  },

  getLocationPathAndQuery: () => {
    if (typeof window !== 'undefined') {
      const location = window.location;
      const page = location.href.replace(location.origin, '');
      return page;
    }
  },

  getDocumentTitle: (): string | undefined => {
    if (typeof window === 'undefined') return undefined;
    return window.document.title;
  },

  getLocation: () => {
    if (typeof window === 'undefined') return undefined;
    return window.location;
  },

  getLocationPath: (): string | undefined => {
    if (typeof window === 'undefined') return undefined;

    const { pathname } = window.location;
    return pathname;
  },

  getLocationOrigin: (): string | undefined => {
    if (typeof window === 'undefined') return undefined;

    const { origin } = window.location;
    return origin;
  },
};

export default WindowUtil;
