import { postData } from '../redux/fetchService/baseFetch';
import { FinancingAPIPayload, FormFields } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

const setNumberIfPresent = (value: string) => (value !== '' ? Number(value) : undefined);

class FinancingAPI {
  fetchFinancing = async (params: {
    formData: FormFields;
    partnerSlug: string;
    installerIds: number[];
  }): Promise<FinancingAPIPayload> => {
    const { zipCode, furnaceType, layoutType, ductedDelivery } = params.formData;
    const { partnerSlug, installerIds } = params;
    return postData('financing', {
      zipCode: setNumberIfPresent(zipCode),
      furnaceType,
      layoutType,
      ductedDelivery,
      partnerSlug,
      installerIds,
    });
  };
}

export const fetchFinancingApiThunk = createApiThunk('financingApi/get', new FinancingAPI().fetchFinancing);
